html {
  background: url('IMG/ya.png') no-repeat center center fixed;
  background-size: cover;
}

.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

/* Header Left (Logo and Socials) */
.header-left {
  position: absolute;
  top: 2vh;
  left: 2vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  height: 15vh;
}

.socials {
  margin-top: 1vh;
  display: flex;
  justify-content: center;
}

.socials img {
  margin: 0 5px;
}

/* Header Right (Join Discord Box) */
.header-right {
  position: absolute;
  top: 2vh;
  right: 2vw;
  z-index: 10;
}

.discord-box {
  background-color: #2c2f33;
  color: #ffffff;
  padding: 15px;
  border-radius: 10px;
  text-align: center;
  width: 300px;
  height: 150px;
  position: relative; /* Ensure children are positioned correctly */
}

.discord-box:hover {
  background-color: #23272a;
}

.join-button {
  background-color: rgba(0, 128, 0, 0.7);
  color: white;
  padding: 10px 20px;
  border: none;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  display: block;
  margin: 10px auto 0;
  transition: background-color 0.3s ease;
}

.join-button:hover {
  background-color: rgba(0, 128, 0, 1);
}

/* Tabs */
.tabs {
  display: flex;
  justify-content: center;
  margin-top: 20vh;
}

.tab-button {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  padding: 10px 20px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
}

.tab-button.active {
  background-color: #ddd;
}

/* Platform Content */
.platform-content {
  margin-top: 1vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

/* Twitch Video and Chat */
.twitch-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.twitch-video iframe {
  width: 70vw;
  height: 75vh;
}

.carlos {
  display: inline-block;
}

#carlotes {
  height: 75vh;
  width: 24vw;
  overflow: hidden;
}

/* Game Servers Section */
.game-servers {
  margin-top: 3vh;
  width: 80vw;
  text-align: center;
}

.server-box {
  position: relative;
  display: inline-block;
  border-radius: 20px;
  width: 200px;
  height: 100px;
  background: #fff;
  margin: 20px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.server-box:hover {
  transform: scale(1.05);
}

.server-image {
  width: 100%;
  height: auto;
}

.server-details {
  margin-top: 10px;
}

.status-light {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
}

.green {
  background-color: green;
}

.red {
  background-color: red;
}

/* PLAY Button for Servers */
.play-button {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 128, 0, 0.7);
  color: white;
  padding: 10px 20px;
  border: none;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  display: none; /* Hidden by default */
  transition: background-color 0.3s ease;
}

.server-box:hover .play-button {
  display: block;
}

.play-button:hover {
  background-color: rgba(0, 128, 0, 1);
}
